import { mouseposition } from '../global/location';
import server from './server';
import luckysheetsizeauto from './resize';
import { modelHTML } from './constant';
import {checkProtectionAuthorityNormal} from './protection';
import { getSheetIndex } from '../methods/get';
import { setluckysheet_scroll_status } from '../methods/set';
import { replaceHtml } from '../utils/util';
import Store from '../store';
import locale from '../locale/locale';

const imageCtrl = {
    imgItem: {
        type: '3',  //1移动并调整单元格大小 2移动并且不调整单元格的大小 3不要移动单元格并调整其大小
        src: '',  //图片url
        originWidth: null,  //图片原始宽度
        originHeight: null,  //图片原始高度
        default: {
            width: null,  //图片 宽度
            height: null,  //图片 高度
            left: null,  //图片离表格左边的 位置
            top: null,  //图片离表格顶部的 位置
        },
        crop: {
            width: null,  //图片裁剪后 宽度
            height: null,  //图片裁剪后 高度
            offsetLeft: 0,  //图片裁剪后离未裁剪时 左边的位移
            offsetTop: 0,  //图片裁剪后离未裁剪时 顶部的位移
        },
        isFixedPos: false,  //固定位置
        fixedLeft: null,  //固定位置 左位移
        fixedTop: null,  //固定位置 右位移
        border: {
            width: 0,  //边框宽度
            radius: 0,  //边框半径
            style: 'solid',  //边框类型
            color: '#000',  //边框颜色
        }
    },
    images: null,
    currentImgId: null,
    currentWinW: null,
    currentWinH: null,
    resize: null,  
    resizeXY: null,
    move: false,
    moveXY: null,
    cropChange: null,  
    cropChangeXY: null,
    cropChangeObj: null,
    copyImgItemObj: null,
    inserImg: function(src){
        let _this = this;

        let rowIndex = Store.luckysheet_select_save[0].row_focus || 0;
        let colIndex = Store.luckysheet_select_save[0].column_focus || 0;
        let left = colIndex == 0 ? 0 : Store.visibledatacolumn[colIndex - 1];
        let top = rowIndex == 0 ? 0 : Store.visibledatarow[rowIndex - 1];

        let image = new Image();
        image.onload = function(){
            let width = image.width,
                height = image.height;

            let img = {
                src: src,
                left: left,
                top: top,
                originWidth: width,
                originHeight: height
            }

            _this.addImgItem(img);
        }
        image.src = src;
    },
    generateRandomId: function(prefix) {
        if(prefix == null){
            prefix = "img";
        }

        let userAgent = window.navigator.userAgent.replace(/[^a-zA-Z0-9]/g, "").split("");

        let mid = "";

        for(let i = 0; i < 12; i++){
            mid += userAgent[Math.round(Math.random() * (userAgent.length - 1))];
        }

        let time = new Date().getTime();

        return prefix + "_" + mid + "_" + time;
    },
    modelHtml_shapeText:function(id,shapeItem){
      let width = shapeItem.default.width*Store.zoomRatio,
      height = shapeItem.default.height*Store.zoomRatio,
      left = shapeItem.default.left*Store.zoomRatio,
      top = shapeItem.default.top*Store.zoomRatio;
      let position='absolute'
      // 翻转变换
      let  flip=shapeItem.default.flip
      // 几何属性
      let  prst=shapeItem.default.prst
      let ln_w=0,ln_c='000000',ln_type = {};
      // 连接线
      ln_w=shapeItem.default.ln_w*Store.zoomRatio;
      ln_c=shapeItem.default.ln_c;
      if(prst=='straightConnector1'){
        ln_type=shapeItem.default.type.type;
        let shapeEnd_w=shapeItem.default.type.width*Store.zoomRatio;
        let shapeEnd_l=shapeItem.default.type.length*Store.zoomRatio;
        let scaleX=1,scaleY=1;
        if(flip.flipH=='1'){
          scaleX=-1
        }
         if(flip.flipV=='1'){
          scaleY=-1
        }
        // 由于直线根据矩形大小对角线，对于箭头可能超出矩形范围则需要动态调整容器大小和偏移量
        let widthAdd=shapeEnd_w/2
        let heightAdd=shapeEnd_l/2
        let offsetWidht=0
        let offsetHeight=0
        if(width<shapeEnd_w/2||(shapeItem.default.headType&&shapeItem.default.headType.type)){
          widthAdd=shapeEnd_l
          offsetWidht=shapeEnd_l/2
          if(shapeItem.default.headType&&shapeItem.default.headType.type){
            heightAdd=shapeEnd_l
            offsetHeight=shapeEnd_l/2
          }
        }
        let fill='none'
        if(ln_type=='triangle'){
          fill='#'+ln_c
        }

        let lineEl=` <line x1="${offsetWidht}" y1="${offsetHeight}" x2="${width+offsetWidht}" y2="${height+offsetHeight}"  stroke-width=${ln_w}  stroke="#${ln_c}" marker-end="url(#arrow${id})" />`
        let markdefs=`<marker id="arrow${id}" markerWidth="${shapeEnd_w}" markerHeight="${shapeEnd_l}" refX="${shapeEnd_w}" refY="${shapeEnd_l/2}" orient="auto">
        <path d="M0,0 L${shapeEnd_w},${shapeEnd_l/2} L0,${shapeEnd_l}" fill="${fill}"  stroke="#${ln_c}"/>
      </marker>`
        if(shapeItem.default.headType&&shapeItem.default.headType.type){
           lineEl=` <line x1="${offsetWidht}" y1="${offsetHeight}" x2="${width+offsetWidht}" y2="${height+offsetHeight}"  stroke-width=${ln_w}  stroke="#${ln_c}" marker-end="url(#arrow${id})" marker-start="url(#arrow-start${id})" />`
           markdefs=` <marker id="arrow-start${id}" markerWidth="${shapeEnd_w}" markerHeight="${shapeEnd_l}" refX="${shapeEnd_w}" refY="${shapeEnd_l/2}" orient="auto-start-reverse">
           <path d="M0,0 L0,${shapeEnd_l} L${shapeEnd_w},${shapeEnd_l/2}" fill="${fill}"  stroke="#${ln_c}"/>
         </marker>
           `+markdefs
        }
         

        return `<svg id="${id}" style="transform:scaleX(${scaleX}) scaleY(${scaleY});width:${width+widthAdd}px;height:${height+heightAdd}px;padding:0;position:${position};left:${left-offsetWidht}px;top:${top-offsetHeight}px;z-index:20000;">
          <defs>
            ${markdefs}
          </defs>
          ${lineEl}
        </svg>`
      }else if(prst=='line'){
        /**
           solid：实线
           dash：虚线
           dot：点线
           dashDot：虚点线
           lgDash：大虚线
           lgDashDot：大虚点线
         */
        let lineConfig={
          solid:'',
          dash:'5 3',
          dot:'2',
          dashDot:'5 3 2 3',
          lgDash:"10 5",
          lgDashDot:"10 5 2 5"
        }
        let prstDash=shapeItem.default.prstDash

          return `<svg id="${id}" style="width:${width}px;height:${height}px;padding:0;position:${position};left:${left}px;top:${top}px;z-index:20000;">
          <line x1="0" y1="0" x2="${width}" y2="${height}"  stroke-width=${ln_w}  stroke="#${ln_c}" marker-end="url(#arrow${id})" stroke-dasharray="${lineConfig[prstDash]}" />
        </svg>`

      }
      
    },
    modelHtml_customeShapeText:function(id,customeShapeItem){
      let width = customeShapeItem.default.width*Store.zoomRatio,
      height = customeShapeItem.default.height*Store.zoomRatio,
      left = customeShapeItem.default.left*Store.zoomRatio,
      top = customeShapeItem.default.top*Store.zoomRatio;
      let position='absolute'
      if(customeShapeItem.default.linePointInfo&&customeShapeItem.default.linePointInfo.pathinfo){
        let pathWidth=customeShapeItem.default.linePointInfo.pathinfo.width*Store.zoomRatio;
        let pathHeight=customeShapeItem.default.linePointInfo.pathinfo.height*Store.zoomRatio;
        if(pathWidth&&pathWidth>width){
          width=pathWidth
        }
        if(pathHeight&&pathHeight>width){
          height=pathHeight
        }
      }
      // 连接线
      let ln_w=customeShapeItem.default.ln_w*Store.zoomRatio;
      let ln_c=customeShapeItem.default.ln_c;
      let linePointInfo=customeShapeItem.default.linePointInfo;
      let startPonit=linePointInfo.startPont;
      let cubicBezTosList=linePointInfo.cubicBezTosList;
      let cubicBezToStr='';
      for(var i=0;i<cubicBezTosList.length;i++){
        if(cubicBezToStr!=''){
          cubicBezToStr=cubicBezToStr+' C';
        }else {
          cubicBezToStr='C';
        }
          let points=cubicBezTosList[i];
          let pointStr='';
          for(var j=0;j<points.length;j++){
            if(j!=0){
              pointStr=pointStr+' '+points[j].x*Store.zoomRatio+','+points[j].y*Store.zoomRatio;
            }else{
              pointStr=pointStr+points[j].x*Store.zoomRatio+','+points[j].y*Store.zoomRatio;
            }
            
          }
          cubicBezToStr=cubicBezToStr+pointStr;
      }
      return `<svg id="${id}" style="width:${width}px;height:${height}px;padding:0;position:${position};left:${left}px;top:${top}px;z-index:20000;">
        <path d="M${startPonit.x*Store.zoomRatio},${startPonit.y*Store.zoomRatio} ${cubicBezToStr}" fill="none" stroke-width=${ln_w}  stroke="#${ln_c}" />
    </svg>`
      
    },
    // 输出textbox标注html add by xiaomw
    modelHtml_remarkText:function(id,remarkTextItem){
      let width = remarkTextItem.default.width*Store.zoomRatio,
      height = remarkTextItem.default.height*Store.zoomRatio,
      left = remarkTextItem.default.left*Store.zoomRatio,
      top = remarkTextItem.default.top*Store.zoomRatio;
      // 批注文字，以换行<a:p> 再通过<a:t>分字体
      let TextArray=remarkTextItem.default.textArray
      let position='absolute'
      let showText=''
      for(var i=0;i<TextArray.length;i++){
        let a_ts=TextArray[i]
        let lineText=''
        if(a_ts!=null&&a_ts.length>0){
          for(var j=0;j<a_ts.length;j++){
            let fontColor='#333'
            if(a_ts[j].fontColor){
              fontColor='#'+a_ts[j].fontColor
            }
            let fontratio=a_ts[j].fontSize/(1000/76)<1?a_ts[j].fontSize/(1000/76):1
            lineText=lineText+"<span style=color:"+ fontColor+";font-size:"+a_ts[j].fontSize +"px;fontFamily:sans-serif;zoom:"+Store.zoomRatio*fontratio+">" +a_ts[j].text+"</span>"
          }
        }
        if(showText!=''){
          showText=showText+ '<br>'+lineText
        }else{
          showText=showText+ lineText
        }
      }
      return `<div id='${id}' style="width:${width+5}px;height:${height}px;padding-left:5px;position:${position};left:${left}px;top:${top}px;z-index:2000;display: flex;align-items: center">
      ${showText}
      <div>`
    },
    modelHtml: function(id, imgItem) {
        let _this = this;

        let src = imgItem.src;
        let imgItemParam = _this.getImgItemParam(imgItem);

        let width = imgItemParam.width*Store.zoomRatio;
        let height = imgItemParam.height*Store.zoomRatio;
        let left = imgItemParam.left*Store.zoomRatio;
        let top = imgItemParam.top*Store.zoomRatio;
        let position = imgItemParam.position;
        let rot=imgItem.default.rot

        let borderWidth = imgItem.border.width;
        // modify by xiaomw 修改图片现实支持裁剪
        return  `<div id="${id}" class="luckysheet-modal-dialog luckysheet-modal-dialog-image" style="width:${width+3}px;height:${height}px;padding:0;position:${position};left:${left}px;top:${top}px;z-index:200;">
                    <div class="luckysheet-modal-dialog-content" style="width:100%;height:100%;overflow:hidden;position:relative;transform:rotate(${rot}deg);">
                        <img src="${src}" style="position:absolute;width:${imgItem.crop.beforeCropWidth*Store.zoomRatio}px;height:${imgItem.crop.beforeCropHeight*Store.zoomRatio}px;transform:translateX(${-imgItem.crop.offsetLeft}%) translateY(${-imgItem.crop.offsetTop}%);" />
                    </div>
                    <div class="luckysheet-modal-dialog-border" style="border:${borderWidth}px ${imgItem.border.style} ${imgItem.border.color};border-radius:${imgItem.border.radius*Store.zoomRatio}px;position:absolute;left:${-borderWidth}px;right:${-borderWidth}px;top:${-borderWidth}px;bottom:${-borderWidth}px;"></div>
                </div>`;
    },
    getSliderHtml: function() {
        let imageText = locale().imageText;

        return `<div id="luckysheet-modal-dialog-slider-imageCtrl" class="luckysheet-modal-dialog-slider luckysheet-modal-dialog-slider-imageCtrl" style="display:block;">
                    <div class="luckysheet-modal-dialog-slider-title">
                        <span>${imageText.imageSetting}</span>
                        <span class="luckysheet-model-close-btn" title="${imageText.close}">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="luckysheet-modal-dialog-slider-content">
                        <div class="slider-box">
                            <div class="slider-box-title">${imageText.conventional}</div>
                            <div class="slider-box-radios">
                                <div class="radio-item">
                                    <input type="radio" id="imgItemType1" name="imgItemType" value="1">
                                    <label for="imgItemType1">${imageText.moveCell1}</label>
                                </div>
                                <div class="radio-item">
                                    <input type="radio" id="imgItemType2" name="imgItemType" value="2">
                                    <label for="imgItemType2">${imageText.moveCell2}</label>
                                </div>
                                <div class="radio-item">
                                    <input type="radio" id="imgItemType3" name="imgItemType" value="3">
                                    <label for="imgItemType3">${imageText.moveCell3}</label>
                                </div>
                            </div>
                            <div class="slider-box-checkbox">
                                <input type="checkbox" id="imgItemIsFixedPos">
                                <label for="imgItemIsFixedPos">${imageText.fixedPos}</label>
                            </div>
                        </div>
                        <div class="slider-box">
                            <div class="slider-box-title">${imageText.border}</div>
                            <div class="slider-box-borderConfig">
                                <div class="border-item">
                                    <label>${imageText.width}</label>
                                    <input type="number" id="imgItemBorderWidth" min="0">
                                </div>
                                <div class="border-item">
                                    <label>${imageText.radius}</label>
                                    <input type="number" id="imgItemBorderRadius" min="0">
                                </div>
                                <div class="border-item">
                                    <label>${imageText.style}</label>
                                    <select id="imgItemBorderStyle">
                                        <option value="solid">${imageText.solid}</option>
                                        <option value="dashed">${imageText.dashed}</option>
                                        <option value="dotted">${imageText.dotted}</option>
                                        <option value="double">${imageText.double}</option>
                                    </select>
                                </div>
                                <div class="border-item">
                                    <label>${imageText.color}</label>
                                    <div id="imgItemBorderColor" class="imgItemBorderColor">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
    },
    sliderHtmlShow: function() {
        let _this = this;

        $("#luckysheet-modal-dialog-slider-imageCtrl").remove();

        let sliderHtml = _this.getSliderHtml();
        $("body").append(sliderHtml);
        luckysheetsizeauto();

        let imgItem = _this.images[_this.currentImgId];

        //类型
        let type = imgItem.type;
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemType" + type).prop("checked", true);

        //固定位置
        let isFixedPos = imgItem.isFixedPos;
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemIsFixedPos").prop("checked", isFixedPos);

        //边框宽度
        let border = imgItem.border;
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemBorderWidth").val(border.width);
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemBorderRadius").val(border.radius);
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemBorderStyle").val(border.style);
        $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemBorderColor span").css("background-color", border.color).attr("title", border.color);
    
        _this.init();
    },
    colorSelectDialog: function(currenColor){
        const _locale = locale();
        const locale_button = _locale.button;
        const locale_toolbar = _locale.toolbar;
        const locale_imageCtrl = _locale.imageCtrl;

        $("#luckysheet-modal-dialog-mask").show();
        $("#luckysheet-imageCtrl-colorSelect-dialog").remove();

        $("body").append(replaceHtml(modelHTML, { 
            "id": "luckysheet-imageCtrl-colorSelect-dialog", 
            "addclass": "luckysheet-imageCtrl-colorSelect-dialog", 
            "title": locale_imageCtrl.borderTile, 
            "content": `<div class="currenColor">
                            ${locale_imageCtrl.borderCur}:<span title="${currenColor}" style="background-color:${currenColor}"></span>
                        </div>
                        <div class="colorshowbox"></div>`, 
            "botton":  `<button id="luckysheet-imageCtrl-colorSelect-dialog-confirm" class="btn btn-primary">${locale_button.confirm}</button>
                        <button class="btn btn-default luckysheet-model-close-btn">${locale_button.cancel}</button>`, 
            "style": "z-index:100003" 
        }));
        let $t = $("#luckysheet-imageCtrl-colorSelect-dialog")
                .find(".luckysheet-modal-dialog-content")
                .css("min-width", 300)
                .end(), 
            myh = $t.outerHeight(), 
            myw = $t.outerWidth();
        let winw = $(window).width(), winh = $(window).height();
        let scrollLeft = $(document).scrollLeft(), scrollTop = $(document).scrollTop();
        $("#luckysheet-imageCtrl-colorSelect-dialog").css({ 
            "left": (winw + scrollLeft - myw) / 2, 
            "top": (winh + scrollTop - myh) / 3 
        }).show();
        
        //初始化选择颜色插件
        $("#luckysheet-imageCtrl-colorSelect-dialog").find(".colorshowbox").spectrum({
            showPalette: true,
            showPaletteOnly: true,
            preferredFormat: "hex",
            clickoutFiresChange: false,
            showInitial: true,
            showInput: true,
            flat: true,
            hideAfterPaletteSelect: true,
            showSelectionPalette: true,
            showButtons: false,//隐藏选择取消按钮
            maxPaletteSize: 8,
            maxSelectionSize: 8,
            color: currenColor,
            cancelText: locale_button.cancel,
            chooseText: locale_toolbar.confirmColor,
            togglePaletteMoreText: locale_toolbar.customColor,
            togglePaletteLessText: locale_toolbar.collapse,
            togglePaletteOnly: true,
            clearText: locale_toolbar.clearText,
            noColorSelectedText: locale_toolbar.noColorSelectedText,
            localStorageKey: "spectrum.textcolor" + server.gridKey,
            palette: [
                ["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"],
                ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"],
                ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"],
                ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"],
                ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"],
                ["#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"],
                ["#900", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"],
                ["#600", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]
            ],
            move: function(color){
                if (color != null) {
                    color = color.toHexString();
                }
                else {
                    color = "#000";
                }

                $("#luckysheet-imageCtrl-colorSelect-dialog .currenColor span").css("background-color", color).attr("title", color);
            }
        });
    },
    init: function() {
        let _this = this;

        //关闭
        $("#luckysheet-modal-dialog-slider-imageCtrl .luckysheet-model-close-btn").click(function () {
            $("#luckysheet-modal-dialog-slider-imageCtrl").hide();
            luckysheetsizeauto();
        });

        //常规
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("change.radio").on("change.radio", ".radio-item input[type=radio][name=imgItemType]", function() {
            _this.configChange("type", this.value);
        })

        //固定位置
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("change.checkbox").on("change.checkbox", ".slider-box-checkbox input[type=checkbox]", function() {
            _this.configChange("fixedPos", this.checked);
        })

        //边框宽度
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("change.borderWidth").on("change.borderWidth", "#imgItemBorderWidth", function() {
            _this.configChange("border-width", this.valueAsNumber);
        })

        //边框半径
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("change.borderRadius").on("change.borderRadius", "#imgItemBorderRadius", function() {
            _this.configChange("border-radius", this.valueAsNumber);
        })

        //边框样式
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("change.borderStyle").on("change.borderStyle", "#imgItemBorderStyle", function() {
            _this.configChange("border-style", this.value);
        })

        //边框颜色 选择
        $("#luckysheet-modal-dialog-slider-imageCtrl").off("click.color").on("click.color", "#imgItemBorderColor", function() {
            let currenColor = $(this).find("span").attr("title");
            _this.colorSelectDialog(currenColor);
        })

        //边框选择颜色 确定 
        $(document).off("click.selectColorConfirm").on("click.selectColorConfirm", "#luckysheet-imageCtrl-colorSelect-dialog-confirm", function(){
            let $parent = $(this).parents("#luckysheet-imageCtrl-colorSelect-dialog");
            $("#luckysheet-modal-dialog-mask").hide();
            $parent.hide();

            let currenColor = $parent.find(".currenColor span").attr("title");
            $("#luckysheet-modal-dialog-slider-imageCtrl #imgItemBorderColor span").css("background-color", currenColor).attr("title", currenColor);

            _this.configChange("border-color", currenColor);            
        });

        // //image active
        // $("#luckysheet-image-showBoxs").off("mousedown.active").on("mousedown.active", ".luckysheet-modal-dialog-image", function(e) {
            

        //     if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "editObjects",false)){
        //         return;
        //     }

        //     $(this).hide();
        //     let id = $(this).attr("id");

        //     if(_this.currentImgId != null && _this.currentImgId != id){
        //         _this.cancelActiveImgItem();
        //     }

        //     _this.currentImgId = id;

        //     let item = _this.images[id];
        //     let imgItemParam = _this.getImgItemParam(item);

        //     let width = imgItemParam.width*Store.zoomRatio;
        //     let height = imgItemParam.height*Store.zoomRatio;
        //     let left = imgItemParam.left*Store.zoomRatio;
        //     let top = imgItemParam.top*Store.zoomRatio;
        //     let position = imgItemParam.position;
        //     let rot=item.default.rot
        
        //     $("#luckysheet-modal-dialog-activeImage").show().css({
        //         "width": width,
        //         "height": height,
        //         "left": left,
        //         "top": top,
        //         "position": position
        //     });

        //     $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-content").css({
        //         "background-image": "url(" + item.src + ")",
        //         'width':item.crop.beforeCropWidth*Store.zoomRatio + "px ",
        //         'height':item.crop.beforeCropHeight*Store.zoomRatio + "px",
        //         // "background-size": item.crop.beforeCropWidth*Store.zoomRatio + "px " + item.crop.beforeCropHeight*Store.zoomRatio + "px",
        //         // "background-position": -item.crop.offsetLeft + "% " + -item.crop.offsetTop + "%",
        //         'transform':"translateX("+ -item.crop.offsetLeft+"%) translateY("+ -item.crop.offsetTop+"%) rotate("+rot+"deg)"
        //     })

        //     $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-border").css({
        //         "border-width": item.border.width*Store.zoomRatio,
        //         "border-style": item.border.style,
        //         "border-color": item.border.color,
        //         "border-radius": item.border.radius*Store.zoomRatio,
        //         "left": -item.border.width*Store.zoomRatio,
        //         "right": -item.border.width*Store.zoomRatio,
        //         "top": -item.border.width*Store.zoomRatio,
        //         "bottom": -item.border.width*Store.zoomRatio,
        //     })

        //     _this.sliderHtmlShow();

        //     e.stopPropagation();
        // })

        //image move
        $("#luckysheet-modal-dialog-activeImage").off("mousedown.move").on("mousedown.move", ".luckysheet-modal-dialog-content", function(e) {
            if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "editObjects",false)){
                return;
            }
            
            if(!$("#luckysheet-modal-dialog-slider-imageCtrl").is(":visible")){
                _this.sliderHtmlShow();
            }
            
            _this.move = true;
            
            _this.currentWinW = $("#luckysheet-cell-main")[0].scrollWidth;
            _this.currentWinH = $("#luckysheet-cell-main")[0].scrollHeight;

            let offset = $("#luckysheet-modal-dialog-activeImage").offset();

            _this.moveXY = [
                e.pageX - offset.left, 
                e.pageY - offset.top, 
            ];

            setluckysheet_scroll_status(true);

            e.stopPropagation();
        })

        //image resize
        $("#luckysheet-modal-dialog-activeImage").off("mousedown.resize").on("mousedown.resize", ".luckysheet-modal-dialog-resize-item", function(e) {
            if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "editObjects",false)){
                return;
            }
            
            _this.currentWinW = $("#luckysheet-cell-main")[0].scrollWidth;
            _this.currentWinH = $("#luckysheet-cell-main")[0].scrollHeight;

            _this.resize = $(this).data("type");

            let scrollTop = $("#luckysheet-cell-main").scrollTop(), 
                scrollLeft = $("#luckysheet-cell-main").scrollLeft();
            let mouse = mouseposition(e.pageX, e.pageY);
            let x = mouse[0] + scrollLeft;
            let y = mouse[1] + scrollTop;

            let position = $("#luckysheet-modal-dialog-activeImage").position();
            let width = $("#luckysheet-modal-dialog-activeImage").width();
            let height = $("#luckysheet-modal-dialog-activeImage").height();

            _this.resizeXY = [
                x, 
                y, 
                width, 
                height, 
                position.left + scrollLeft, 
                position.top + scrollTop, 
                scrollLeft, 
                scrollTop
            ];

            setluckysheet_scroll_status(true);
            
            e.stopPropagation();
        })

        //image croppingEnter
        $("#luckysheet-modal-dialog-activeImage").off("mousedown.croppingEnter").on("mousedown.croppingEnter", ".luckysheet-modal-controll-crop", function(e) {
            _this.croppingEnter();
            e.stopPropagation();
        })

        //image croppingExit
        $("#luckysheet-modal-dialog-cropping").off("mousedown.croppingExit").on("mousedown.croppingExit", ".luckysheet-modal-controll-crop", function(e) {
            _this.croppingExit();
            e.stopPropagation();
        })

        //image crop change
        $("#luckysheet-modal-dialog-cropping").off("mousedown.cropChange").on("mousedown.cropChange", ".resize-item", function(e) {
            _this.cropChange = $(this).data("type");

            let scrollTop = $("#luckysheet-cell-main").scrollTop(), 
                scrollLeft = $("#luckysheet-cell-main").scrollLeft();
            let mouse = mouseposition(e.pageX, e.pageY);
            let x = mouse[0] + scrollLeft;
            let y = mouse[1] + scrollTop;

            _this.cropChangeXY = [
                x, 
                y
            ];

            setluckysheet_scroll_status(true);
            
            e.stopPropagation();
        })

        //image restore
        $("#luckysheet-image-showBoxs").off("mousedown.restore").on("mousedown.restore", ".luckysheet-modal-controll-restore", function(e) {
            _this.restoreImgItem();
            e.stopPropagation();
        })

        //image delete
        $("#luckysheet-image-showBoxs").off("mousedown.delete").on("mousedown.delete", ".luckysheet-modal-controll-del", function(e) {
            _this.removeImgItem();
            e.stopPropagation();
        })
    },
    configChange: function(type, value){
        let _this = this;

        let imgItem = _this.images[_this.currentImgId];

        switch(type){
            case "type":
                imgItem.type = value;
                break;
            case "fixedPos":
                imgItem.isFixedPos = value;

                let imgItemParam = _this.getImgItemParam(imgItem);
                let width = imgItemParam.width;
                let height = imgItemParam.height;
                let left = imgItemParam.left;
                let top = imgItemParam.top;
                let position = imgItemParam.position;
            
                $("#luckysheet-modal-dialog-activeImage").show().css({
                    "width": width,
                    "height": height,
                    "left": left,
                    "top": top,
                    "position": position
                });
                break;
            case "border-width":
                imgItem.border.width = value;
                $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-border").css({
                    "border-width": value,
                    "left": -value,
                    "right": -value,
                    "top": -value,
                    "bottom": -value
                });
                break;
            case "border-radius":
                imgItem.border.radius = value;
                $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-border").css("border-radius", value);
                break;
            case "border-style":
                imgItem.border.style = value;
                $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-border").css("border-style", value);
                break;
            case "border-color":
                imgItem.border.color = value;
                $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-border").css("border-color", value);
                break;
        }
        
        _this.ref();
    },
    getImgItemParam(imgItem){
        let isFixedPos = imgItem.isFixedPos;

        let width = imgItem.default.width,
            height = imgItem.default.height,
            left = imgItem.default.left,
            top = imgItem.default.top;

        if(imgItem.crop.width != width || imgItem.crop.height != height){
            width = imgItem.crop.width;
            height = imgItem.crop.height;
            // 考虑是否需要该项偏移 remove by xiaomw
            // left += imgItem.crop.offsetLeft;
            // top += imgItem.crop.offsetTop;
        }

        let position = 'absolute';
        if(isFixedPos){
            position = 'fixed';
            left = imgItem.fixedLeft + imgItem.crop.offsetLeft;
            top = imgItem.fixedTop + imgItem.crop.offsetTop;
        }

        return {
            width: width,
            height: height,
            left: left,
            top: top,
            position: position
        }
    },
    cancelActiveImgItem: function(){
        let _this = this;

        $("#luckysheet-modal-dialog-activeImage").hide();
        $("#luckysheet-modal-dialog-cropping").hide();
        $("#luckysheet-modal-dialog-slider-imageCtrl").hide();

        let imgItem = _this.images[_this.currentImgId];
        let imgItemParam = _this.getImgItemParam(imgItem);

        let width = imgItemParam.width*Store.zoomRatio;
        let height = imgItemParam.height*Store.zoomRatio;
        let left = imgItemParam.left*Store.zoomRatio;
        let top = imgItemParam.top*Store.zoomRatio;
        let position = imgItemParam.position;

        $("#" + _this.currentImgId).show().css({
            "width": width,
            "height": height,
            "left": left,
            "top": top,
            "position": position
        });
        $("#" + _this.currentImgId + " img").css({
            "width": imgItem.default.width*Store.zoomRatio,
            "height": imgItem.default.height*Store.zoomRatio,
            "left": -imgItem.crop.offsetLeft*Store.zoomRatio,
            "top": -imgItem.crop.offsetTop*Store.zoomRatio
        });
        $("#" + _this.currentImgId + " .luckysheet-modal-dialog-border").css({
            "border-width": imgItem.border.width*Store.zoomRatio,
            "border-style": imgItem.border.style,
            "border-color": imgItem.border.color,
            "border-radius": imgItem.border.radius*Store.zoomRatio,
            "left": -imgItem.border.width*Store.zoomRatio,
            "right": -imgItem.border.width*Store.zoomRatio,
            "top": -imgItem.border.width*Store.zoomRatio,
            "bottom": -imgItem.border.width*Store.zoomRatio,
        })

        _this.currentImgId = null;
    },
    addImgItem: function(img) {
        let _this = this;

        let width, height;
        let max = 400;

        if(img.originHeight < img.originWidth){
            height = Math.round(img.originHeight * (max / img.originWidth));
            width = max;
        }
        else{
            width = Math.round(img.originWidth * (max / img.originHeight));
            height = max;
        }

        if(_this.images == null){
            _this.images = {};
        }

        let imgItem = $.extend(true, {}, _this.imgItem);
        imgItem.src = img.src;
        imgItem.originWidth = img.originWidth;
        imgItem.originHeight = img.originHeight;
        imgItem.default.width = width;
        imgItem.default.height = height;
        imgItem.default.left = img.left;
        imgItem.default.top = img.top;
        imgItem.crop.width = width;
        imgItem.crop.height = height;

        let scrollTop = $("#luckysheet-cell-main").scrollTop(), 
            scrollLeft = $("#luckysheet-cell-main").scrollLeft();

        imgItem.fixedLeft = img.left - scrollLeft + Store.rowHeaderWidth;
        imgItem.fixedTop = img.top - scrollTop + Store.infobarHeight + Store.toolbarHeight + Store.calculatebarHeight + Store.columeHeaderHeight;

        let id = _this.generateRandomId();
        let modelHtml = _this.modelHtml(id, imgItem);

        $("#luckysheet-image-showBoxs .img-list").append(modelHtml);

        _this.images[id] = imgItem;
        _this.ref();

        _this.init();
    },
    moveImgItem: function() {
        let _this = this;

        _this.move = false;

        let obj = $("#luckysheet-modal-dialog-activeImage")[0];
        let item = _this.images[_this.currentImgId];

        if(item.isFixedPos){
            item.fixedLeft = obj.offsetLeft - item.crop.offsetLeft;
            item.fixedTop = obj.offsetTop - item.crop.offsetTop;
        }
        else{
            item.default.left = obj.offsetLeft - item.crop.offsetLeft;
            item.default.top = obj.offsetTop - item.crop.offsetTop;
        }

        _this.ref();
    },
    resizeImgItem: function() {
        let _this = this;

        _this.resize = null;

        let obj = $("#luckysheet-modal-dialog-activeImage")[0];

        let item = _this.images[_this.currentImgId];
        let scaleX = obj.clientWidth / item.crop.width;
        let scaleY = obj.clientHeight / item.crop.height;

        item.default.width = Math.round(item.default.width * scaleX);
        item.default.height = Math.round(item.default.height * scaleY);

        item.crop.width = Math.round(item.crop.width * scaleX);
        item.crop.height = Math.round(item.crop.height * scaleY);
        item.crop.offsetLeft = Math.round(item.crop.offsetLeft * scaleX);
        item.crop.offsetTop = Math.round(item.crop.offsetTop * scaleY);

        if(item.isFixedPos){
            item.fixedLeft = obj.offsetLeft;
            item.fixedTop = obj.offsetTop;
        }
        else{
            item.default.left = obj.offsetLeft - item.crop.offsetLeft;
            item.default.top = obj.offsetTop - item.crop.offsetTop;
        }

        _this.ref();
    },
    croppingEnter: function() {
        let _this = this;
        _this.cropping = true;

        if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "editObjects",false)){
            return;
        }

        $("#luckysheet-modal-dialog-activeImage").hide();
        $("#luckysheet-modal-dialog-slider-imageCtrl").hide();

        let item = _this.images[_this.currentImgId];
        let imgItemParam = _this.getImgItemParam(item);

        let width = imgItemParam.width;
        let height = imgItemParam.height;
        let left = imgItemParam.left;
        let top = imgItemParam.top;
        let position = imgItemParam.position;
    
        $("#luckysheet-modal-dialog-cropping").show().css({
            "width": width,
            "height": height,
            "left": left,
            "top": top,
            "position": position
        });

        $("#luckysheet-modal-dialog-cropping .cropping-mask").css({
            "width": item.default.width,
            "height": item.default.height,
            "background-image": "url(" + item.src + ")",
            "left": -item.crop.offsetLeft,
            "top": -item.crop.offsetTop
        })

        $("#luckysheet-modal-dialog-cropping .cropping-content").css({
            "background-image": "url(" + item.src + ")",
            "background-size": item.default.width + "px " + item.default.height + "px",
            "background-position": -item.crop.offsetLeft + "px " + -item.crop.offsetTop + "px"
        })

        $("#luckysheet-modal-dialog-cropping .luckysheet-modal-dialog-border").css({
            "border-width": item.border.width,
            "border-style": item.border.style,
            "border-color": item.border.color,
            "border-radius": item.border.radius,
            "left": -item.border.width,
            "right": -item.border.width,
            "top": -item.border.width,
            "bottom": -item.border.width,
        })
    },
    croppingExit: function() {
        let _this = this;
        _this.cropping = false;

        $("#luckysheet-modal-dialog-cropping").hide();

        let item = _this.images[_this.currentImgId];
        let imgItemParam = _this.getImgItemParam(item);

        let width = imgItemParam.width;
        let height = imgItemParam.height;
        let left = imgItemParam.left;
        let top = imgItemParam.top;
        let position = imgItemParam.position;

        $("#luckysheet-modal-dialog-activeImage").show().css({
            "width": width,
            "height": height,
            "left": left,
            "top": top,
            "position": position
        });

        $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-content").css({
            "background-image": "url(" + item.src + ")",
            "background-size": item.default.width + "px " + item.default.height + "px",
            "background-position": -item.crop.offsetLeft + "px " + -item.crop.offsetTop + "px"
        })
    },
    cropChangeImgItem: function() {
        let _this = this;

        _this.cropChange = null;

        let item = _this.images[_this.currentImgId];
        item.crop.width = _this.cropChangeObj.width;
        item.crop.height = _this.cropChangeObj.height;
        item.crop.offsetLeft = _this.cropChangeObj.offsetLeft;
        item.crop.offsetTop = _this.cropChangeObj.offsetTop;

        _this.ref();
    },
    restoreImgItem: function() {
        let _this = this;
        let imgItem = _this.images[_this.currentImgId];

        imgItem.default.width = imgItem.originWidth;
        imgItem.default.height = imgItem.originHeight;

        imgItem.crop.width = imgItem.originWidth;
        imgItem.crop.height = imgItem.originHeight;
        imgItem.crop.offsetLeft = 0;
        imgItem.crop.offsetTop = 0;

        let imgItemParam = _this.getImgItemParam(imgItem);

        let width = imgItemParam.width;
        let height = imgItemParam.height;
        let left = imgItemParam.left;
        let top = imgItemParam.top;
        let position = imgItemParam.position;
        
        $("#luckysheet-modal-dialog-activeImage").show().css({
            "width": width,
            "height": height,
            "left": left,
            "top": top,
            "position": position
        });

        $("#luckysheet-modal-dialog-activeImage .luckysheet-modal-dialog-content").css({
            "background-image": "url(" + imgItem.src + ")",
            "background-size": imgItem.default.width + "px " + imgItem.default.height + "px",
            "background-position": -imgItem.crop.offsetLeft + "px " + -imgItem.crop.offsetTop + "px"
        })

        _this.ref();
    },
    removeImgItem: function() {
        let _this = this;
        
        $("#luckysheet-modal-dialog-activeImage").hide();
        $("#luckysheet-modal-dialog-cropping").hide();
        $("#luckysheet-modal-dialog-slider-imageCtrl").hide();
        $("#" + _this.currentImgId).remove();

        delete _this.images[_this.currentImgId];
        _this.currentImgId = null;

        _this.ref();
    },
    copyImgItem: function(e) {
        let _this = this;

        _this.copyImgItemObj = $.extend(true, {}, _this.images[_this.currentImgId]);

        let clipboardData = window.clipboardData; //for IE
        if (!clipboardData) { // for chrome
            clipboardData = e.originalEvent.clipboardData;
        }

        let cpdata = '<table data-type="luckysheet_copy_action_image"><tr><td><td></tr></table>';

        if (!clipboardData) {
            let textarea = $("#luckysheet-copy-content");
            textarea.html(cpdata);
            textarea.focus();
            textarea.select();
            document.execCommand("selectAll");
            document.execCommand("Copy");
            // 等50毫秒，keyPress事件发生了再去处理数据
            setTimeout(function () { 
                $("#luckysheet-copy-content").blur(); 
            }, 10);
        }
        else {
            clipboardData.setData('Text', cpdata);
            return false;//否则设不生效
        }
    },
    pasteImgItem: function() {
        let _this = this;

        let rowIndex = Store.luckysheet_select_save[0].row_focus || 0;
        let colIndex = Store.luckysheet_select_save[0].column_focus || 0;
        let left = colIndex == 0 ? 0 : Store.visibledatacolumn[colIndex - 1];
        let top = rowIndex == 0 ? 0 : Store.visibledatarow[rowIndex - 1];

        let img = $.extend(true, {}, _this.copyImgItemObj);
        
        img.default.left = left - img.crop.offsetLeft;
        img.default.top = top - img.crop.offsetTop;

        let scrollTop = $("#luckysheet-cell-main").scrollTop(), 
            scrollLeft = $("#luckysheet-cell-main").scrollLeft();

        img.fixedLeft = img.default.left - scrollLeft + Store.rowHeaderWidth;
        img.fixedTop = img.default.top - scrollTop + Store.infobarHeight + Store.toolbarHeight + Store.calculatebarHeight + Store.columeHeaderHeight;

        let id = _this.generateRandomId();
        let modelHtml = _this.modelHtml(id, img);

        $("#luckysheet-image-showBoxs .img-list").append(modelHtml);

        _this.images[id] = img;
        _this.ref();

        _this.init();
    },
    allImagesShow: function() {
        let _this = this;
        
        $("#luckysheet-modal-dialog-activeImage").hide();
        $("#luckysheet-modal-dialog-cropping").hide();
        $("#luckysheet-modal-dialog-slider-imageCtrl").hide();
        $("#luckysheet-image-showBoxs .img-list").empty();

        if(_this.images == null){
            return;
        }

        for(let imgId in _this.images){
            let imgItem = _this.images[imgId];
            let modelHtml = _this.modelHtml(imgId, imgItem);
            $("#luckysheet-image-showBoxs .img-list").append(modelHtml);
        }
    },
    // 图形的显示 add by xiaomw
    allSharpShow:function(){
      let _this=this
      let flag=0
      // 连接符
      if(_this.shapes != null){
        flag=1
      }
       // 自定义图形
      if(_this.customeShapes != null){
        flag=flag+2
      }
      if(flag==0){
        return
      }
      if(flag==1||flag==3){
        for(let shapeId in _this.shapes){
          let shapeItem = _this.shapes[shapeId];
          let modelHtml = _this.modelHtml_shapeText(shapeId, shapeItem);
          $("#luckysheet-image-showBoxs .img-list").append(modelHtml);
        }
      } 
      if(flag==2||flag==3){
        for(let customeShapeId in _this.customeShapes){
          let customeShapeItem = _this.customeShapes[customeShapeId];
          let modelHtml = _this.modelHtml_customeShapeText(customeShapeId, customeShapeItem);
          $("#luckysheet-image-showBoxs .img-list").append(modelHtml);
        }
      }
     
    },

    // 借助图片容器，蒋插入文字定位显示在不同的地方 add by xiaomw
    allRemarkTextShow:function(){
      let _this = this;
      if(_this.remarkTexts == null){
        return;
      }
      for(let remarkTextId in _this.remarkTexts){
        let remarkTextItem = _this.remarkTexts[remarkTextId];
        let modelHtml = _this.modelHtml_remarkText(remarkTextId, remarkTextItem);
        $("#luckysheet-image-showBoxs .img-list").append(modelHtml);
      }
    },
    moveChangeSize: function(rc, index, size) {
        let _this = this;
        let images = $.extend(true, {}, _this.images);

        if(rc == "row"){
            let row = Store.visibledatarow[index], 
                row_pre = index - 1 == -1 ? 0 : Store.visibledatarow[index - 1];
            let changeSize = size - (row - row_pre - 1);
            
            for(let imgId in images){
                let imgItem = images[imgId];
                let imgItemParam = _this.getImgItemParam(imgItem);
                let type = imgItem.type;

                if(type == "1"){
                    if(imgItemParam.top >= row){
                        imgItem.default.top = imgItemParam.top + changeSize - imgItem.crop.offsetTop;
                    }
                    else{
                        if(imgItemParam.top + imgItemParam.height >= row-2){
                            if(imgItemParam.top < row + changeSize){
                                let scaleY = (imgItemParam.height + changeSize) / imgItemParam.height;
                                imgItem.default.height = Math.round(imgItem.default.height * scaleY);
                                imgItem.crop.height = Math.round(imgItem.crop.height * scaleY);
                                imgItem.crop.offsetTop = Math.round(imgItem.crop.offsetTop * scaleY);
                            }
                            else{
                                let scaleY = (imgItemParam.top + imgItemParam.height - row) / imgItemParam.height;
                                imgItem.default.height = Math.round(imgItem.default.height * scaleY);
                                imgItem.crop.height = Math.round(imgItem.crop.height * scaleY);
                                imgItem.crop.offsetTop = Math.round(imgItem.crop.offsetTop * scaleY);
                                imgItem.default.top = row + changeSize - imgItem.crop.offsetTop;
                            }
                        }
                        else{
                            if(imgItemParam.top > row + changeSize){
                                let scaleY = 1 / imgItemParam.height;
                                imgItem.default.height = Math.round(imgItem.default.height * scaleY);
                                imgItem.crop.height = Math.round(imgItem.crop.height * scaleY);
                                imgItem.crop.offsetTop = Math.round(imgItem.crop.offsetTop * scaleY);
                                imgItem.default.top = row + changeSize - imgItem.crop.offsetTop;
                            }
                            else if(imgItemParam.top + imgItemParam.height > row + changeSize){
                                let scaleY = (row + changeSize - imgItemParam.top) / imgItemParam.height;
                                imgItem.default.height = Math.round(imgItem.default.height * scaleY);
                                imgItem.crop.height = Math.round(imgItem.crop.height * scaleY);
                                imgItem.crop.offsetTop = Math.round(imgItem.crop.offsetTop * scaleY);
                            }
                        }
                    }
                }
                else if(type == "2"){
                    if(imgItemParam.top >= row){
                        imgItem.default.top = imgItemParam.top + changeSize - imgItem.crop.offsetTop;
                    }
                    else if(imgItemParam.top > row + changeSize){
                        imgItem.default.top = row + changeSize - imgItem.crop.offsetTop;
                    }
                }
            }
        }
        else if(rc == "column"){
            let col = Store.visibledatacolumn[index], 
                col_pre = index - 1 == -1 ? 0 : Store.visibledatacolumn[index - 1];
            let changeSize = size - (col - col_pre - 1);

            for(let imgId in images){
                let imgItem = images[imgId];
                let imgItemParam = _this.getImgItemParam(imgItem);
                let type = imgItem.type;

                if(type == "1"){
                    if(imgItemParam.left >= col){
                        imgItem.default.left = imgItemParam.left + changeSize - imgItem.crop.offsetLeft;
                    }
                    else{
                        if(imgItemParam.left + imgItemParam.width >= col-2){
                            if(imgItemParam.left < col + changeSize){
                                let scaleX = (imgItemParam.width + changeSize) / imgItemParam.width;
                                imgItem.default.width = Math.round(imgItem.default.width * scaleX);
                                imgItem.crop.width = Math.round(imgItem.crop.width * scaleX);
                                imgItem.crop.offsetLeft = Math.round(imgItem.crop.offsetLeft * scaleX);
                            }
                            else{
                                let scaleX = (imgItemParam.left + imgItemParam.width - col) / imgItemParam.width;
                                imgItem.default.width = Math.round(imgItem.default.width * scaleX);
                                imgItem.crop.width = Math.round(imgItem.crop.width * scaleX);
                                imgItem.crop.offsetLeft = Math.round(imgItem.crop.offsetLeft * scaleX);
                                imgItem.default.left = col + changeSize - imgItem.crop.offsetLeft;
                            }
                        }
                        else{
                            if(imgItemParam.left > col + changeSize){
                                let scaleX = 1 / imgItemParam.width;
                                imgItem.default.width = Math.round(imgItem.default.width * scaleX);
                                imgItem.crop.width = Math.round(imgItem.crop.width * scaleX);
                                imgItem.crop.offsetLeft = Math.round(imgItem.crop.offsetLeft * scaleX);
                                imgItem.default.left = col + changeSize - imgItem.crop.offsetLeft;
                            }
                            else if(imgItemParam.left + imgItemParam.width > col + changeSize){
                                let scaleX = (col + changeSize - imgItemParam.left) / imgItemParam.width;
                                imgItem.default.width = Math.round(imgItem.default.width * scaleX);
                                imgItem.crop.width = Math.round(imgItem.crop.width * scaleX);
                                imgItem.crop.offsetLeft = Math.round(imgItem.crop.offsetLeft * scaleX);
                            }
                        }
                    }
                }
                else if(type == "2"){
                    if(imgItemParam.left >= col){
                        imgItem.default.left = imgItemParam.left + changeSize - imgItem.crop.offsetLeft;
                    }
                    else if(imgItemParam.left > col + changeSize){
                        imgItem.default.left = col + changeSize - imgItem.crop.offsetLeft;
                    }
                }
            }
        }

        return images;
    },
    ref: function() {
        let _this = this;

        let file = Store.luckysheetfile[getSheetIndex(Store.currentSheetIndex)];
        let images = _this.images;

        if (Store.clearjfundo) {
            Store.jfundo = [];

            Store.jfredo.push({
                "type": "imageCtrl",
                "sheetIndex": Store.currentSheetIndex,
                "images": file.images == null ? null : $.extend(true, {}, file.images),
                "curImages": images
            });
        }

        file.images = $.extend(true, {}, images);
        server.saveParam("all", Store.currentSheetIndex, file.images, { "k": "images" });
    },
}

export default imageCtrl;